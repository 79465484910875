import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from '../../../../shared/hooks/use-app-dispatch';
import { findContactAffiliations } from '../../../../modules/contacts';
import { getUserSettings } from '../../../../modules/settings';
import { formatPhoneForCall } from '../../../../utils/formatters';

type HeaderProps = {
  projectData: any;
  projectBidData?: any;
  setClientsCallback?: (clients: any[]) => void;
};

export const Header = ({
  projectData = {},
  projectBidData = {},
  setClientsCallback,
}: HeaderProps) => {
  const {
    jobName,
    jobAddress,
    jobAddress2,
    jobCity,
    jobState,
    jobZip,
    jobNumFormatted,
    altJobNum,
    projectTeam = {},
  } = projectData;

  const [clients, setClients] = useState<Array<any>>([]);
  const [clientRoleId, setClientRoleId] = useState<string>('');

  const dispatch = useAppDispatch();

  const { clientArr: legacy_bidClientArr } = projectBidData;
  const bidTeam = projectBidData?.projectTeam || {};
  const bidClientArr = bidTeam[clientRoleId] || legacy_bidClientArr;
  const projectTeamClientArr = projectTeam[clientRoleId];

  useEffect(() => {
    dispatch(getUserSettings(['projectRoleTypes']))
      .unwrap()
      .then((roles) => {
        const clRoleId = roles.find((role: any) => role.code === 'CL')?._id || '';
        setClientRoleId(clRoleId);
      });
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const clientAffsIds = bidClientArr || projectTeamClientArr || [];

      if (clientAffsIds.length > 0 && clients.length === 0) {
        const clientsData = await dispatch(
          findContactAffiliations({
            criteria: `${clientAffsIds.join(' ')}`,
            page: 1,
            count: clientAffsIds.length,
            isAccount: null,
            includeDeleted: true,
            fields: ['_id'],
          })
        ).unwrap();

        setClients(clientsData);
        if (setClientsCallback && typeof setClientsCallback === 'function') {
          setClientsCallback(clientsData);
        }
      }
    };

    fetchClients();
  }, [bidClientArr, projectTeamClientArr, clients]);

  const getClients = useCallback(() => {
    return (
      <ul>
        {clients.map(
          ({ _id, companyName, contactFullName = '', contactId, primaryEmail, primaryPhone }) => {
            const contactName = contactId ? contactFullName : '';
            const clientName = [companyName, contactName].filter((text) => text).join(' ');
            const email = primaryEmail?.email || '';
            const phone = formatPhoneForCall(primaryPhone || {}) || '';

            return (
              <li key={_id} className='mb-1'>
                {clientName && (
                  <>
                    {clientName}
                    <br />
                  </>
                )}
                {email && (
                  <>
                    {email}
                    <br />
                  </>
                )}
                {phone && (
                  <>
                    {phone}
                    <br />
                  </>
                )}
              </li>
            );
          }
        )}
      </ul>
    );
  }, [clients]);

  const getJobInfo = useCallback(() => {
    const jobCityStateZip = [jobCity, jobState, jobZip].filter((part) => part).join(', ');
    const jobAddressParts = [jobName, jobAddress, jobAddress2, jobCityStateZip].map(part=>(part?.trim())).filter(Boolean);

    return (
      <ul className='mb-2'>
        {jobAddressParts.map((part, index) => ( 
          <li key={index}>{part}</li>
        ))}
      </ul>
    );
  }, [jobName, jobAddress, jobAddress2, jobCity, jobState, jobZip]);

  const getJobNumbers = useCallback(
    () => (
      <ul>
        <li>{jobNumFormatted}</li>
        <li>{altJobNum}</li>
      </ul>
    ),
    [jobNumFormatted, altJobNum]
  );

  const getBidNumber = useCallback(
    () => (
      <ul>
        <li>
          V{projectBidData.bidCount || 0}.{projectBidData.bidRev || 0}
        </li>
      </ul>
    ),
    [projectBidData.bidCount, projectBidData.bidRev]
  );

  return (
    <div className='row' data-testId='bidManagementModalHeader'>
      <div className='col-md-4 pe-2'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Client(s):</span>
          {getClients()}
        </div>
      </div>
      <div className='col-md-4 pe-2'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Job Info:</span>
          {getJobInfo()}
        </div>
      </div>
      <div className='col-md-4'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Job Number:</span>
          {getJobNumbers()}
          {projectBidData.bidCount && (
            <>
              <span className='fw-bold'>Bid Version:</span>
              {getBidNumber()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
