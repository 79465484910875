import { forwardRef, useImperativeHandle, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDurationPicker from '../../../../shared/form-controls/form-duration-picker/form-duration-picker';
import FormDatePicker from '../../../../shared/form-controls/form-date-picker/form-date-picker';
import { formatDateObjectOrNull } from '../../../../utils/date-formatters';
import { convertIntervalToPreciseDuration } from '../../../../utils/import-utils';
import { isCurrentOrPrevStage } from '../../../../shared/custom-fields-controls/constants';

interface InlineLayoutProps {
  label: string;
  prefix: string;
  required?: boolean;
  error?: string;
  startInput: React.ReactNode;
  durationInput: React.ReactNode;
  endInput: React.ReactNode;
}

const InlineLayout = ({ label, prefix, required, error, startInput, durationInput, endInput }: InlineLayoutProps) => {
  return (
    <div className='form-group row mb-3'>
      <label className='col-md-4 col-form-label fw-bold'>
        {label} {required && <span className='text-danger'>*</span>}
      </label>
      <div className='col-md-8'>
        <div className='d-flex'>
          <div className='flex-grow-0 d-flex flex-column'>
            <label className='mb-1 small fw-bold'>
              {prefix} Start</label>
            {startInput}
          </div>
          <div className='mx-2 flex-grow-1 d-flex flex-column'>
            <label className='mb-1 small fw-bold'>Duration</label>
            {durationInput}
          </div>
          <div className='flex-grow-0 d-flex flex-column'>
            <label className='mb-1 small fw-bold'>
              {prefix} End </label>
            {endInput}
          </div>
          {error && <div className='invalid-feedback d-block'>{error}</div>}
        </div>
      </div>
    </div>
  );
};

type ProjectSchedulerProps = {
  isLocked: boolean;
  projectStage?: string;
  labelPrefix?: string;
  accountSettings?: any;
};

export const ProjectScheduler = forwardRef(({
  isLocked,
  projectStage = '',
  labelPrefix = 'Contract',
  accountSettings = {},
}: ProjectSchedulerProps, ref) => {
  const form = useFormContext();

  const watchStartDate = form.watch('startDate');
  const watchEndDate = form.watch('endDate');
  const watchBidDue = form.watch('bidDue');

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  useImperativeHandle(ref, () => ({ setIsExpanded }), []);

  const dateFormat = 'MM/DD/YYYY';

  const bidDueFormatted = formatDateObjectOrNull(watchBidDue);
  const startDateFormatted = formatDateObjectOrNull(watchStartDate);
  const endDateFormatted = formatDateObjectOrNull(watchEndDate);

  const startDateRangeProps = {
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
  };

  const endDateRangeProps = {
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
    ...(bidDueFormatted && { minDate: bidDueFormatted.clone()}),
    ...(startDateFormatted && { minDate: startDateFormatted.clone() }),
    ...(startDateFormatted && { maxDate: startDateFormatted.clone().add(120, 'months') }),
  };

  const { requireConstructionDatesOn } = accountSettings;
  const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);

  const getCollapsedSchedule = () => {
    let startDateFormatted = '<Unknown>';
    let endDateFormatted = '<Unknown>';
    let durationText = '';

    if (watchStartDate && watchEndDate) {
      const { years, months, days } = convertIntervalToPreciseDuration(watchStartDate, watchEndDate);
      let durationArray = [{label: 'y', value: years}, {label: 'm', value: months}, {label: 'd', value: days}];
      const firstPeriodWithDurationIndex = durationArray.findIndex((el) => el.value > 0);

      if (firstPeriodWithDurationIndex !== -1) {
        durationArray = durationArray.slice(firstPeriodWithDurationIndex);
        durationText = `(${durationArray.map(({ label, value }) => `${value}${label}`).join(' ')})`;
      }

      startDateFormatted = watchStartDate.format(dateFormat);
      endDateFormatted = watchEndDate.format(dateFormat);
    }

    return `${startDateFormatted} - ${endDateFormatted} ${durationText}`;
  };

  const getExpandedSchedule = () => {
    return (
      <InlineLayout 
        label={`${labelPrefix} dates:`}
        prefix={labelPrefix}
        required={requireConstructionDates}
        startInput={
          <FormDatePicker
            required={requireConstructionDates}
            label=""
            name='startDate'
            selectsStart
            disabled={isLocked}
            control={form.control}
            placeholderText="Start Date"
            {...startDateRangeProps}
          />
        }
        durationInput={
          <FormDurationPicker
            label=''
            name='projectDuration'
            disabled={isLocked}
            startDateFieldName='startDate'
            endDateFieldName='endDate'
            inlineStyle={true}
          />
        }
        endInput={
          <FormDatePicker
            required={requireConstructionDates}
            label=""
            name='endDate'
            selectsEnd
            disabled={isLocked}
            control={form.control}
            placeholderText="End Date"
            {...endDateRangeProps}
          />
        }
      />
    );
  };

  return (
    <div data-testId='bidManagementModalProjectScheduler'>
      <hr />
    {!isExpanded&&<div className='form-group row my-4'>
        <div className={`col-md-4 ${isExpanded ? 'fw-bold' : ''}`}>Construction schedule:</div>
        <div className='col-md-6'>{!isExpanded && getCollapsedSchedule()}</div>
        </div>}
        
      {isExpanded && getExpandedSchedule()}
    </div>
  );
});